module services {
    export module logistics {
        export class consignmentCustomsDetailService implements interfaces.logistics.IConsignmentCustomsDetailService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            delete(consignmentDetailId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ConsignmentCustomsDetail/Delete", {
                    codId: consignmentDetailId
                });
            }
        }
    }
    angular.module("app").service("consignmentCustomsDetailService", services.logistics.consignmentCustomsDetailService);
}